<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <div class="brand-logo">
        <vuexy-logo />
      </div>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5 mx-auto"
          style="max-width: 90%"
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ $t('page.login.header') }} 👋
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('page.login.subheader') }}
          </b-card-text>

          <!-- form -->
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >
            <!-- email -->
            <b-form-group
              label-for="username"
              label="Ваш номер телефона"
            >
              <VuePhoneNumberInput
                v-model="phone"
                v-validate="'phone-is-correct'"
                data-vv-validate-on="blur"
                class="w-100"
                color="#7367f0"
                :dark="$store.state.appConfig.layout.skin === 'dark'"
                valid-color="#28A745"
                error-color="#DC3545"
                no-example
                :translations="{
                  countrySelectorLabel: 'Страна',
                  countrySelectorError: 'Ошибка',
                  phoneNumberLabel: 'введите номер телефона',
                  example: 'Пример :'
                }"
                :only-countries="['RU', 'BY', 'KZ', 'AM']"
                name="phone"
                @update="phoneUpdated"
              />
              <!--              <b-form-input-->
              <!--                id="email"-->
              <!--                v-model="username"-->
              <!--                v-validate="'required'"-->
              <!--                :placeholder="$t('page.login.username')"-->
              <!--                name="username"-->
              <!--                data-vv-as="Имя пользователя"-->
              <!--                :state="errors.first('username') ? false : null"-->
              <!--                autofocus-->
              <!--              />-->
              <small class="text-danger">{{ errors.first('username') }}</small>
            </b-form-group>

            <!-- password -->
            <b-form-group
              label-for="password"
              class="mb-50"
              :label="$t('page.login.password')"
            >

              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
                label-for="password"
                :label="$t('page.login.password')"
              >
                <b-form-input
                  id="password"
                  v-model="password"
                  v-validate="'required'"
                  :type="passwordFieldType"
                  :placeholder="$t('page.login.password')"
                  class="form-control-merge"
                  name="password"
                  data-vv-as="Пароль"
                  :state="errors.first('password') || loginErrors.password ? false : null"
                />

                <b-input-group-append
                  v-if="!errors.first('password') && !loginErrors.password"
                  is-text
                >
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>

              <small class="text-danger">{{ errors.first('password') || loginErrors.password }}</small>
            </b-form-group>

            <b-link :to="{ name: 'auth.password_reset' }">
              <small>&nbsp;Забыли пароль? </small>
            </b-link>

            <!-- submit button -->
            <b-button
              class="mt-2"
              variant="primary"
              type="submit"
              block
              :disabled="loading"
            >
              <b-spinner
                v-if="loading"
                small
              />
              <span v-else>{{ $t('page.login.title') }}</span>
            </b-button>
          </b-form>

          <b-card-text class="text-center mt-2">
            <span>{{ $t('page.login.register_hint') }} </span>
            <b-link :to="{ name: 'auth.register' }">
              <span>&nbsp;{{ $t('page.register.title') }}</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BImg, BForm, BButton, BSpinner,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { mapActions } from 'vuex'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import VuePhoneNumberInput from 'vue-phone-number-input'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    BSpinner,
    VuePhoneNumberInput,
  },
  mixins: [togglePasswordVisibility],
  data: () => ({
    phone: '',
    password: '',
    username: '',
    sideImg: require('@/assets/images/pages/login-v1.svg'),
    loginErrors: {
      password: null,
    },
    loading: false,
    phonePayload: null,
  }),
  computed: {
    dict() {
      return {
        custom: {
          username: {
            required: 'Необходимо указать имя пользователя',
          },
          phone: {
            required: 'Необходимо указать телефон',
            'phone-is-correct': 'Телефон введен некорректно',
          },
          password: {
            required: 'Необходимо ввести пароль',
            'credentials-are-correct': 'Неверный логин или пароль',
          },
        },
      }
    },
    phoneIsValid() {
      if (!this.phonePayload) return false
      return this.phonePayload.isValid
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v1-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    this.$validator.extend('phone-is-correct', () => ({
      valid: this.phoneIsValid,
      data: {
        required: true,
      },
    }),
    {
      computesRequired: true,
    })
    this.$validator.localize('ru', this.dict)
  },
  methods: {
    ...mapActions({
      setUser: 'user/setUser',
    }),
    phoneUpdated(payload) {
      this.phonePayload = payload
    },
    async login() {
      this.loginErrors = {}
      await this.$validator.validate().then(valid => {
        if (valid) {
          this.loading = true
          useJwt.login({
            username: this.phonePayload.formattedNumber.replace(/\D/g, ''),
            password: this.password,
          })
            .then(async response => {
              await this.setUser(response)
              await this.$router.push({ name: 'cabinet.dashboard' })
              this.loading = false
            })
            .catch(errors => {
              this.loginErrors = errors
              this.loading = false
            })
        }
        return null
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-auth.scss';
</style>
